import ActiveDesktop from "../ActiveDesktop";
import { ReactComponent as SlideIcon } from "../../../../assets/icons/angle-left.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { notification, Badge, Tooltip } from "antd";
import { Link } from "react-router-dom";
import useWindowSize from "../../../../hooks/useWindowSize";
import { getSessions } from "../../../../data/slices/sessions";
import useServerSentEvents from "../../hooks/useServerSentEvents";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as SessionsIcon } from "./icons/pc.svg";

function calculateTimeDifference(dateStr) {
  // Extract the date and time part using a regular expression
  const regex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;
  const extractedDate = dateStr.match(regex);

  if (!extractedDate) {
    return "Invalid date";
  }

  const givenDate = new Date(extractedDate[0]);

  const currentDate = new Date();

  const timeDiff = givenDate - currentDate;

  if (isNaN(timeDiff)) {
    return "Invalid date";
  }

  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")}`;

  return formattedTime;
}

const sessionConnected = (session, connectedSessions) => {
  return connectedSessions?.find((item) => item?.sessionID === session?.name);
};

const DesktopsHeader = () => {
  const sessionsDataSSE = useServerSentEvents(
    `https://api.gomydesk.com/api/sessions/alerts/listen?token=${localStorage.getItem(
      "gomydesk_token"
    )}`
  );

  useEffect(() => {
    if (sessionsDataSSE?.sessions?.length > 0) {
      calculateTimeDifference(
        sessionsDataSSE?.deleteAt || "2023-11-21 11:30:05.52088 +0100 CET m=+0.003656959"
      );
      notification.info({
        message: `You have ${
          sessionsDataSSE?.sessions?.length || "0"
        } sessions that will close in one minutes because your plan is ending. You might want to consider upgrading.`
      });
    }
  }, [sessionsDataSSE]);

  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [smallView, setSmallView] = useState(false);
  const containerElement = document.querySelector(".active_desktops");

  const { desktopSessions, connectedSessions } = useSelector((state) => state.sessions);
  let sessions = desktopSessions.data;
  const MaxSessions = containerElement?.clientWidth / 220;

  useEffect(() => {
    if (sessions?.length === 0) dispatch(getSessions());
  }, []);

  useEffect(() => {
    if (sessions?.length > MaxSessions) setSmallView(true);
    else setSmallView(false);
  }, [sessions, width, MaxSessions]);

  const swiperRef = useRef();

  useEffect(() => {
    swiperRef.current?.slideTo(sessions?.length - 1);
  }, [sessions?.length]);

  return (
    <div className="desktops_header_page" id={smallView ? "smallView" : ""}>
      {sessions?.length > 0 ? (
        <>
          <div className="active_sessions">
            <p>Active Sessions</p> <span>{sessions?.length}</span>
          </div>

          {/* <Badge count={sessions?.length} offset={[-3, 5]}>
            <Tooltip placement="bottom" title={"Active Sessions"} arrow={true}>
              <div className="active_sessions">
                <Link to="/">
                  <div className="desktops_header_page--home">
                    <SessionsIcon />
                  </div>
                </Link>
              </div>
            </Tooltip>
          </Badge> */}

          <div className="my-custom-swiper">
            <Swiper
              spaceBetween={12}
              slidesPerView={"auto"}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {sessions?.map((el, index) => (
                <SwiperSlide key={index} className="my-custom-swiper-item">
                  <ActiveDesktop
                    session={{
                      ...el,
                      isConnected: !!sessionConnected(el, connectedSessions),
                      timer: sessionConnected(el, connectedSessions)?.timer
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="desktops_header_page_my_arrow">
            <div
              className="desktops_header_page_my_arrow_btn desktops_header_page_my_arrow_btn_reverse"
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <SlideIcon />
            </div>

            <div
              className="desktops_header_page_my_arrow_btn"
              onClick={() => swiperRef.current?.slideNext()}
            >
              <SlideIcon />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DesktopsHeader;
