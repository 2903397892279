import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const GuestGuard = ({ children }) => {
  const searchParams = new URLSearchParams(document.location.search);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const callbackURL = searchParams.get("callbackURL");

  if (isAuthenticated) {
    if (callbackURL && callbackURL !== "/" && callbackURL.indexOf("?state=") === -1) {
      return <Navigate to={"/" + callbackURL ? callbackURL : ""} />;
    }

    return <Navigate to={"/"} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
