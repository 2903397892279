import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "..";
import axiosInstance from "../../utils/axios";
import axios from "../../utils/axios";
import { message } from "antd";

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const auth_request = async (state) => {
  const stateToken = localStorage.getItem("state");
  if (stateToken) return stateToken;
  const newStateToken = uuidv4();
  localStorage.setItem("state", newStateToken);
  return newStateToken;
};

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  history: null,
  profileOrg: null,
  error: null,
  message: null,
  state: null,
  stateToken: null,
  domainToken: null,
  googleLoginLoading: false,
  requestData: null
};

export const setSession = async (token) => {
  if (token) {
    localStorage.setItem("gomydesk_token", token);
    axiosInstance.defaults.headers.common["X-Session-Token"] = `${token}`;
  } else {
    await axios.post(`auth/logout`);
    localStorage.removeItem("gomydesk_token");
    delete axiosInstance.defaults.headers.common["X-Session-Token"];
  }
};

export const signup = createAsyncThunk("auth/signup", async ({ values, query }) => {
  let data;
  try {
    const response = await axiosInstance.post(
      `auth/signup`,
      {
        ...values
      },
      { params: { ...query } }
    );
    data = await response.data;

    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    console.log(err);
    // message.error(err.error || "something went wrong");

    return Promise.reject(error.message ? error.message : err.error);
  }
});
//accept invite
export const signupInvited = createAsyncThunk("auth/accept-invite", async ({ values, query }) => {
  let data;
  try {
    const response = await axiosInstance.post(
      `auth/accept-invite`,
      {
        ...values
      },
      { params: { ...query } }
    );
    data = await response.data;

    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    console.log(err);
    // message.error(err.error || "something went wrong");

    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const login = createAsyncThunk("auth/login", async (query) => {
  const { username, password } = query;
  let data;
  try {
    const stateToken = await auth_request();
    const response = await axiosInstance.post(`auth/login`, {
      username,
      password,
      state: stateToken
    });
    data = await response.data;
    const resState = response.data.state;
    if (stateToken !== resState) throw new Error("State token was malformed during request flow!");

    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    console.log(err);
    // message.error(err.error || "something went wrong");

    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const getProfileHistory = createAsyncThunk("api/get-profile-histories", async () => {
  let data;
  try {
    const response = await axios.get(`profile/device-activities`);

    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getRequestData = createAsyncThunk("getRequestData", async () => {
  let data;
  try {
    const response = await axiosInstance.post(`data/export`, {
      message: "data export"
    });

    data = await response.data;
    return data;
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const ldapLogin = createAsyncThunk("auth/ldap-login", async (query) => {
  const { username, password } = query;
  let data;
  try {
    const stateToken = await auth_request();
    const response = await axiosInstance.post(`auth/ldap/login`, {
      username,
      password,
      state: stateToken
    });
    data = await response.data;
    const resState = response.data.state;
    if (stateToken !== resState) throw new Error("State token was malformed during request flow!");

    if (response.status === 200) {
      // const response = await axios.get("/whoami");
      // const user = response.data;
      // store.dispatch(initialise({ isAuthenticated: true, user }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    console.log(err);
    // message.error(err.error || "something went wrong");

    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const oauthLogin = createAsyncThunk("auth/oauthLogin", async (query) => {
  const { access_token } = query;
  let data;
  try {
    const stateToken = await auth_request();
    const response = await axiosInstance.post(`auth/oidc/login`, {
      code: access_token,
      state: stateToken
    });
    data = await response.data;
    const resState = response.data.state;
    if (stateToken !== resState) throw new Error("State token was malformed during request flow!");
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    console.log(err);
    // message.error(err.error || "something went wrong");

    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const refreshToken = createAsyncThunk("auth/refresh", async (query) => {
  let data;
  try {
    const response = await axiosInstance.get(`auth/refresh_token`);
    data = await response.data;
    const newAccessToken = data.token;
    localStorage.setItem("gomydesk_token", newAccessToken);
    axiosInstance.defaults.headers.common["X-Session-Token"] = newAccessToken;

    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const forgotPassword = createAsyncThunk("auth/forgot-password", async (query) => {
  const { email } = query;
  let data;
  try {
    const response = await axios.post(`auth/forgot-password`, { email });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const resetPassword = createAsyncThunk("auth/reset-password", async (query) => {
  const { email, resetCode, password, passwordConfirm } = query;
  let data;
  try {
    const response = await axios.post(`auth/reset-password`, {
      email,
      resetCode,
      password,
      passwordConfirm
    });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const googleLoginAuth = createAsyncThunk("oidc/google", async (query) => {
  let data;
  try {
    const response = await axiosInstance.post(
      `auth/login/oidc/google`,
      {},
      { params: { ...query } }
    );
    data = await response.data;

    window.open(data?.redirectUrl, "_self");

    if (response.status === 200) {
      return data;
    }

    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const googleRegisterAuth = createAsyncThunk("oidc/google/register", async (query) => {
  let data;
  try {
    const response = await axiosInstance.post(
      `auth/signup/oidc/google`,
      {},
      { params: { ...query } }
    );
    data = await response.data;

    if (response.status === 200) {
      window.open(data?.redirectUrl, "_self");

      return data;
    }

    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const googleLoginAuthVerify = createAsyncThunk(
  "auth/login/oidc/callback/google",
  async (query) => {
    let data;
    try {
      const response = await axiosInstance.post(`auth/login/oidc/callback/google?${query}`);
      data = await response.data;

      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : err.error);
    }
  }
);

export const googleRegisterAuthVerify = createAsyncThunk(
  "auth/signup/oidc/callback/google",
  async (query) => {
    let data;
    try {
      const response = await axiosInstance.post(`auth/signup/oidc/callback/google?${query}`);
      data = await response.data;

      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : err.error);
    }
  }
);

export const getProfileOrg = createAsyncThunk("api/orgs/me", async () => {
  let data;
  try {
    const response = await axios.get(`orgs/me`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getOrgTokenDomain = createAsyncThunk("api/orgs/domain-token", async (domain) => {
  let data;
  try {
    const response = await axios.post(`orgs/domain-token`, domain);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    message.error(error?.error);
    return Promise.reject(error.message ? error.message : error);
  }
});

export const verifyOrgTokenDomain = createAsyncThunk(
  "api/orgs/domain-verification",
  async (domain) => {
    let data;
    try {
      const response = await axios.post(`orgs/domain-verification`, domain);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const deleteProfile = createAsyncThunk("deleteProfile", async (_, { rejectWithValue }) => {
  let data;
  try {
    const response = await axios.delete(`profile/remove`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : err.error);
  }
});

export const deleteOrganisation = createAsyncThunk(
  "deleteOrganisation",
  async (_, { rejectWithValue }) => {
    let data;
    try {
      const response = await axios.delete(`profile/remove/org`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : err.error);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initialise: (state, action) => {
      const { isAuthenticated, user } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.isInitialised = true;
      state.user = user;
    },
    restore: (state) => {
      state.error = null;
      state.message = null;
    },
    logout: (state) => {
      // window.location.pathname = "/";
      setSession(null);
      state.isAuthenticated = false;
      state.user = null;

      // resetBillingData();
      // resetCategoriesData();
      // resetCurrentPlanData();
      // resetFeedbackData();
      // resetimageGroupsSliceData();
      // resetldapSliceData();
      // resetmemberSliceData();
      // resetnetworkPolicySliceData();
      // resetoffersSliceData();
      // resetpendingSliceData();
      // resetresourcesForCreateSliceData();
      // resetsessionsSliceData();
      // resetsettingsSliceData();
      // resetoffersSliceData();
      // resetTemplatesData();
      // resetusersGroupSliceData();
    },
    profileOrgEdit: (state, action) => {
      state.profileOrg = action;
    }
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [login.fulfilled]: (state, action) => {
      const { token, user } = action.payload;
      setSession(token);
      state.isAuthenticated = true;
      state.user = user;
      state.state = "success";
    },
    [login.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [googleLoginAuthVerify.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [googleLoginAuthVerify.fulfilled]: (state, action) => {
      const { token, user } = action.payload;
      setSession(token);
      state.isAuthenticated = true;
      state.user = user;
      state.state = "success";
    },
    [googleLoginAuthVerify.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    //register with google
    [googleRegisterAuthVerify.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [googleRegisterAuthVerify.fulfilled]: (state, action) => {
      const { token, user } = action.payload;
      setSession(token);
      state.isAuthenticated = true;
      state.user = user;
      state.state = "success";
    },
    [googleRegisterAuthVerify.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    ///
    [googleLoginAuth.pending]: (state) => {
      state.googleLoginLoading = true;
    },
    [googleLoginAuth.fulfilled]: (state, action) => {
      state.googleLoginLoading = false;
    },
    [googleLoginAuth.rejected]: (state, action) => {
      state.googleLoginLoading = false;
    },
    //signup
    [signup.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [signup.fulfilled]: (state, action) => {
      const { token, user } = action.payload;
      setSession(token);
      state.isAuthenticated = true;
      state.user = user;
      state.state = "success";
    },
    [signup.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    //signup as invited
    [signupInvited.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [signupInvited.fulfilled]: (state, action) => {
      const { token, user } = action.payload;
      setSession(token);
      state.isAuthenticated = true;
      state.user = user;
      state.state = "success";
    },
    [signupInvited.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [getProfileHistory.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getProfileHistory.fulfilled]: (state, action) => {
      state.history = action.payload;
      state.state = "success";
    },
    [getProfileHistory.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [ldapLogin.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [ldapLogin.fulfilled]: (state, action) => {
      const { token, user } = action.payload;
      setSession(token);
      state.isAuthenticated = true;
      state.user = user;
      state.state = "success";
    },
    [ldapLogin.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [oauthLogin.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [oauthLogin.fulfilled]: (state, action) => {
      const { token, user } = action.payload;
      setSession(token);
      state.isAuthenticated = true;
      state.user = user;
      state.state = "success";
    },
    [oauthLogin.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [forgotPassword.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.state = "success";
    },
    [forgotPassword.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [resetPassword.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.state = "success";
    },
    [resetPassword.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [getProfileOrg.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getProfileOrg.fulfilled]: (state, action) => {
      state.profileOrg = action.payload.spec;
      state.state = "success";
    },
    [getProfileOrg.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
  }
});

export const { initialise, logout, restore, profileOrgEdit } = authSlice.actions;

export default authSlice.reducer;
