import { configureStore } from "@reduxjs/toolkit";
import exampleReducer from "./slices/exampleSlice";
import authReducer from "./slices/authSlice";
import { reducer as modalsReducer } from "./slices/modals";
import settingsReducer from "./slices/settingsSlice";
import templatesReducer from "./slices/templates";
import categoriesReducer from "./slices/categoriesTemplates";
import sessionsReducer from "./slices/sessions";
import memberReducer from "./slices/memberSlice";
import tabsReducer from "./slices/tabsSlice";
import usersGroupReducer from "./slices/userGroup";
import imageGroupsReducer from "./slices/groups";
import sortReducer from "./slices/sortSlice";
import billingReducer from "./slices/billingSlice";
import ldapReducer from "./slices/ldapSlice";
import offersReducer from "./slices/offers";
import feedbackReducer from "./slices/feedback";
import statsReducer from "./slices/statisticSlice";
import resourcesVerbsReducer from "./slices/resourcesSlice";
import pendingUserReducer from "./slices/pendingUsersSlice";
import networkPolicyReducer from "./slices/networkPolicy";
import quotaManagementReducer from "./slices/quotaManagementSlice";
import currentPlanReducer from "./slices/currentPlan";
import snapshotReducer from "./slices/snapshot";
import notificationReducer from "./slices/notificationSlice";

export const store = configureStore({
  reducer: {
    example: exampleReducer,
    auth: authReducer,
    modals: modalsReducer,
    settings: settingsReducer,
    templates: templatesReducer,
    categories: categoriesReducer,
    sessions: sessionsReducer,
    member: memberReducer,
    quota: quotaManagementReducer,
    tabs: tabsReducer,
    usersGroup: usersGroupReducer,
    imageGroups: imageGroupsReducer,
    sort: sortReducer,
    billing: billingReducer,
    ldap: ldapReducer,
    offers: offersReducer,
    feedback: feedbackReducer,
    stats: statsReducer,
    resources_verbs: resourcesVerbsReducer,
    pending: pendingUserReducer,
    networkPolicy: networkPolicyReducer,
    currentPlan: currentPlanReducer,
    snapshot: snapshotReducer,
    notification: notificationReducer
  },
  // devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
