import { Select, Tag } from "antd";

function InputSelect({
  formik,
  options,
  defaultOptions,
  onChange,
  label,
  field,
  value,
  mode,
  onDropdownVisibleChange,
  placeholder,
  disabled,
  allowClear,
  notFoundContent
}) {
  return (
    <span className="select">
      <label htmlFor={label}>{label}</label>
      <Select
        status={formik.errors[field] && formik.touched[field] ? "error" : null}
        mode={mode || "multiple"}
        showArrow
        tagRender={Tag}
        style={{
          width: "100%"
        }}
        onChange={onChange}
        placeholder={placeholder || "Select"}
        options={options}
        defaultValue={defaultOptions}
        value={value}
        onDropdownVisibleChange={onDropdownVisibleChange}
        disabled={disabled}
        allowClear={allowClear}
        notFoundContent={notFoundContent}
      ></Select>
    </span>
  );
}

export default InputSelect;
