import { Checkbox, Switch, message } from "antd";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../data/slices/modals";
import { useEffect, useState } from "react";
import { newSession, new_session } from "../../../../data/slices/sessions";
import { useNavigate, useSearchParams } from "react-router-dom";
import LowConfig from "../../../../assets/images/configlow.svg";
import MedConfig from "../../../../assets/images/configmed.svg";
import HighConfig from "../../../../assets/images/confighight.svg";
import { ReactComponent as ConfigCpuIcon } from "../../../../assets/images/config-cpu.svg";
import { ReactComponent as ConfigDiskIcon } from "../../../../assets/images/config-disk.svg";
import { ReactComponent as ConfigRamIcon } from "../../../../assets/images/config-ram.svg";
import ConfigItem from "./components/ConfigItem";
import PersonaliseItem from "./components/PersonaliseItem";
import { getCurrentPlan } from "../../../../data/slices/currentPlan";
import FeatureProvider from "../../../../providers/FeaturesProvider/FeatureProvider";
import { useTranslation } from "react-i18next";
import BooleanFeature from "./components/BooleanFeature/BooleanFeature";

const showValidationErrors = (errors = {}) => {
  Object.values(errors).forEach((error) => message.error(error));
};

const templateFormValidation = (values, formik) => {
  let errors = {};
  if (!formik.isSubmitting) return {};
  const { cpu, storage, ram } = values;
  if (!cpu) errors = { ...errors, cores: "cpu is reaqiured" };
  if (!storage) errors = { ...errors, disk: "storage is required" };
  if (!ram) errors = { ...errors, ram: "ram is required" };
  showValidationErrors(errors);
  return errors;
};

function TemplateForm({ id, desktop }) {
  const { t } = useTranslation("index");
  const [searchParams, _setSearchParams] = useSearchParams();
  const type = searchParams.get("type");

  const [personalise, setPersonalise] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { currentPlan } = useSelector((state) => state.currentPlan);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { ram, storage, cpu, gpu } = desktop?.skuConfig || {};

  const formik = useFormik({
    initialValues: {
      cpu: cpu?.defaultValue || "",
      gpu: gpu?.defaultValue || "0",
      storage: storage?.defaultValue || "",
      ram: ram?.defaultValue || "",
      shouldPersist: false
    },
    validate: desktop?.skuConfig ? (val) => templateFormValidation(val, formik) : () => {},
    onSubmit: (values) => {
      if (currentPlan?.gpuFeature?.number === 0) {
        values.gpu = "0";
      }
      if (!isLoading) {
        setLoading(true);
        if (desktop) {
          dispatch(
            newSession({
              namespace: "default",
              template: desktop.name,
              sessionConfig: values,
              shouldPersist: values.shouldPersist
            })
          )
            .unwrap()
            .then((res) => {
              if (res) {
                dispatch(new_session(res));
                dispatch(closeModal("template-modal"));
                navigate(`/control/${res.name}${type === "demo" ? "?type=demo" : ""}`, {
                  state: res
                });
              }
            })
            .catch((err) => {
              dispatch(closeModal("template-modal"));
              message.error(err.message);
            });
        }
      }
    }
  });

  const cpuMarks = () => {
    let marks = {};
    cpu?.allowedValues.map((item, index) => {
      marks[index] = item;
    });
    return marks;
  };

  const storageMarks = () => {
    let marks = {};
    storage?.allowedValues.map((item, index) => {
      marks[index] = item;
    });
    return marks;
  };

  const ramMarks = () => {
    let marks = {};
    ram?.allowedValues.map((item, index) => {
      marks[index] = item;
    });
    return marks;
  };

  const gpuMarks = () => {
    let marks = {};
    let indexOffset = 0;

    // Check if 0 is in the allowedValues array
    // if (!gpu?.allowedValues.includes(0)) {
    //   marks[0] = "0";
    //   indexOffset = 1; // Adjust index offset if 0 is added
    // }

    // Populate marks with allowedValues
    gpu?.allowedValues.forEach((item, index) => {
      marks[index + indexOffset] = item;
    });

    return marks;
  };

  const getMediumValues = (arr) => {
    const middleIndex = Math.floor(arr?.length / 2);
    return arr[middleIndex];
  };

  const disableHigh = () => {
    return (
      cpu?.allowedValues?.length === 1 &&
      storage?.allowedValues?.length === 1 &&
      ram?.allowedValues?.length === 1
    );
  };

  const disableMiddle = () => {
    return (
      cpu?.allowedValues?.length < 2 ||
      storage?.allowedValues?.length < 2 ||
      ram?.allowedValues?.length < 2
    );
  };

  useEffect(() => {
    dispatch(getCurrentPlan());
  }, []);

  const isDefaultConfigActive = () => {
    let result = true;

    if (
      formik.values.cpu === cpu?.allowedValues[0] &&
      formik.values.storage === storage?.allowedValues[0] &&
      formik.values.ram === ram?.allowedValues[0]
    ) {
      result = false;
    }

    if (
      formik.values.cpu === getMediumValues(cpu?.allowedValues) &&
      formik.values.storage === getMediumValues(storage?.allowedValues) &&
      formik.values.ram === getMediumValues(ram?.allowedValues)
    ) {
      result = false;
    }

    if (
      !disableHigh() &&
      formik.values.cpu === cpu?.allowedValues.at(-1) &&
      formik.values.storage === storage?.allowedValues.at(-1) &&
      formik.values.ram === ram?.allowedValues.at(-1)
    ) {
      return false;
    }

    setPersonalise(result);
  };

  useEffect(() => {
    isDefaultConfigActive();
  }, []);

  const stringBoolean = (item) => {
    if (item === true || item === "true") {
      return true;
    }

    if (item === false || item === "false") {
      return false;
    }
    return false;
  };

  const [selectedConfig, setSelectedConfig] = useState(1);
  const [allowedGpu, setAllowedGpu] = useState(formik.values.gpu !== "0");

  return (
    <div className="template-form form">
      <form action="" onSubmit={formik.handleSubmit}>
        <FeatureProvider status={currentPlan?.skuConfigurationFeature?.isOn} type="lock">
          {/* <div className={"blured-content"}> */}
          <div className={personalise ? "config-rows grayscale-effect" : "config-rows"}>
            <ConfigItem
              setSelectedConfig={() => setSelectedConfig(1)}
              img={LowConfig}
              cpu={cpu?.allowedValues[0]}
              disk={storage?.allowedValues[0]}
              ram={ram?.allowedValues[0]}
              formik={formik}
              isPersonalise={personalise}
              isActive={
                selectedConfig === 1 &&
                formik.values.cpu === cpu?.allowedValues[0] &&
                formik.values.storage === storage?.allowedValues[0] &&
                formik.values.ram === ram?.allowedValues[0]
              }
              // isDisabled={!currentPlan?.skuConfigurationFeature?.isOn}
            />

            {!disableMiddle() && (
              <ConfigItem
                setSelectedConfig={() => setSelectedConfig(2)}
                img={MedConfig}
                cpu={getMediumValues(cpu?.allowedValues)}
                disk={getMediumValues(storage?.allowedValues)}
                ram={getMediumValues(ram?.allowedValues)}
                formik={formik}
                isDisabled={disableMiddle()}
                isPersonalise={personalise}
                isActive={
                  selectedConfig === 2 &&
                  formik.values.cpu === getMediumValues(cpu?.allowedValues) &&
                  formik.values.storage === getMediumValues(storage?.allowedValues) &&
                  formik.values.ram === getMediumValues(ram?.allowedValues)
                }
              />
            )}

            {!disableHigh() && (
              <ConfigItem
                setSelectedConfig={() => setSelectedConfig(3)}
                img={HighConfig}
                cpu={cpu?.allowedValues.at(-1)}
                disk={storage?.allowedValues.at(-1)}
                ram={ram?.allowedValues.at(-1)}
                formik={formik}
                isDisabled={disableHigh()}
                isPersonalise={personalise}
                isActive={
                  selectedConfig === 3 &&
                  !disableHigh() &&
                  formik.values.cpu === cpu?.allowedValues.at(-1) &&
                  formik.values.storage === storage?.allowedValues.at(-1) &&
                  formik.values.ram === ram?.allowedValues.at(-1)
                }
              />
            )}
          </div>

          <div className="personalise">
            <p>{t("home.TemplateModal-3")}</p>
            <hr />

            {personalise !== null && (
              <Switch
                defaultChecked={personalise ? true : false}
                size="small"
                onChange={() => setPersonalise(!personalise)}
                disabled={!currentPlan?.skuConfigurationFeature?.isOn}
              />
            )}
          </div>
          {personalise ? (
            <div className={personalise ? "" : "grayscale-effect"}>
              {/* <PersonaliseItem
                icon={<ConfigCpuIcon />}
                title={t("home.TemplateModal-11")}
                marks={gpuMarks()}
                formik={formik}
                type="gpu"
                item={gpu}
              /> */}
              <PersonaliseItem
                icon={<ConfigCpuIcon />}
                title={t("home.TemplateModal-4")}
                marks={cpuMarks()}
                formik={formik}
                type="cpu"
                item={cpu}
              />

              <PersonaliseItem
                icon={<ConfigDiskIcon />}
                title={t("home.TemplateModal-5")}
                marks={storageMarks()}
                formik={formik}
                type="storage"
                item={storage}
              />

              <PersonaliseItem
                icon={<ConfigRamIcon />}
                title={t("home.TemplateModal-6")}
                marks={ramMarks()}
                formik={formik}
                type="ram"
                item={ram}
              />
            </div>
          ) : null}
        </FeatureProvider>

        {currentPlan?.persistentStorageFeature?.isOn && (
          <BooleanFeature
            title={t("home.TemplateModal-7")}
            icon={""}
            checked={formik.values.shouldPersist}
            onChange={(e) => formik.setFieldValue("shouldPersist", stringBoolean(e))}
            disabled={!currentPlan?.persistentStorageFeature?.isOn}
          />
        )}

        {gpu ? (
          <div className="gpu">
            <BooleanFeature
              title={t("home.TemplateModal-12")}
              icon={""}
              checked={allowedGpu}
              onChange={(e) => {
                setAllowedGpu(e);
                formik.setFieldValue("gpu", e ? gpu?.defaultValue : "0");
              }}
              disabled={currentPlan?.gpuFeature?.number === 0}
              type="gpu"
              content={
                allowedGpu ? (
                  <FeatureProvider status={currentPlan?.gpuFeature?.number > 0} type="lock">
                    <PersonaliseItem
                      // icon={<ConfigCpuIcon />}
                      // title={t("home.TemplateModal-11")}
                      marks={gpuMarks()}
                      formik={formik}
                      type="gpu"
                      item={gpu}
                    />
                  </FeatureProvider>
                ) : null
              }
            />
          </div>
        ) : null}
        {/* </div> */}

        <div className="action_form">
          <button
            type="reset"
            onClick={() => {
              dispatch(closeModal("template-modal"));
              formik.setValues({});
              formik.setErrors({ cancel: "true" });
            }}
            className="dashboard-cancel-btn"
          >
            {t("home.TemplateModal-8")}
          </button>
          <button disabled={isLoading} type="submit" className="dashboard-confirm-btn">
            {isLoading ? t("home.TemplateModal-9") : t("home.TemplateModal-10")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default TemplateForm;
