import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

const initialState = {
  currentPlan: null,
  currentPriceId: null,
  planStatus: null,
  error: null,
  isLoading: false,
  cancelPlanLoading: false
};

export const getCurrentPlan = createAsyncThunk("api/current-plan", async () => {
  let data;
  try {
    const response = await axiosInstance.get(`/subscriptions/org`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }

    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const cancelPlan = createAsyncThunk("api/cancel-current-plan", async (_, thunkApi) => {
  let data;
  try {
    const response = await axiosInstance.delete(`/subscriptions/cancel`);
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getCurrentPlan());
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const currentPlanSlice = createSlice({
  name: "currentPlan",
  initialState,
  reducers: {
    resetCurrentPlanData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    //get current plan features
    [getCurrentPlan.pending]: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    [getCurrentPlan.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.currentPlan = action?.payload?.plan;
        state.planStatus = action?.payload?.status;
        state.currentPriceId = action?.payload?.price;
      }
      state.isLoading = false;
    },
    [getCurrentPlan.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
      state.isLoading = false;
    },
    //cancel plan
    [cancelPlan.pending]: (state) => {
      state.error = null;
      state.cancelPlanLoading = true;
    },
    [cancelPlan.fulfilled]: (state, action) => {
      state.cancelPlanLoading = false;
    },
    [cancelPlan.rejected]: (state, action) => {
      state.state = "error";
      state.cancelPlanLoading = false;
    }
  }
});

export const { resetCurrentPlanData } = currentPlanSlice.actions;

export default currentPlanSlice.reducer;
